<template>
  <div class='login'>
    <div class="top">
      <p><img src="../../assets/hi.png" alt="" srcset=""></p>
      <h3>欢迎登录<span>现场取证</span></h3>
    </div>
    <div class="content">
      <van-form @submit="onSubmit">
        <van-field v-model="username" name="username" placeholder="用户名" :rules="[{  required: true, message: '请填写用户名' }]" />
        <van-field v-model="password" type="password" name="password" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" />
        <div>
          <van-button type="primary" :loading="loginLoading" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getCurrent } from '@/api'
export default {
  data () {
    return {
      password: '',
      username: '',
      loginLoading: false
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 登录
    onSubmit (values) {
      // console.log('submit', values);
      this.loginLoading = true
      this.$store.dispatch("user/Login", values).then(res => {
        if (res!='error') {
          getCurrent().then(res => {
            if (res.code === 200) {
              localStorage.setItem('userName', res.data.user.nickname)
              this.$router.push('/home').catch(() => { })
            }
          })
          this.loginLoading = false;
        } else {
          this.loginLoading = false
        }
      }).catch(() => {
        this.loginLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  height: 100vh;
  background: linear-gradient(92deg, #1284ff, #43abff);
  display: flex;
  flex-direction: column;
  .top {
    padding: 98px 0 36px 27px;
    p {
      width: 47px;
      height: 46px;
      img {
        width: 100%;
        height: 100%;
      }
      margin-bottom: 10px;
    }
    h3 {
      line-height: 1;
      font-size: 20px;
      color: #fff;
      font-weight: normal;
      span {
        margin-left: 6px;
        font-size: 28px;
        font-weight: bold;
        // transform: skew(80deg);
      }
    }
  }
  .content {
    background: #ffffff;
    border-radius: 24px 24px 0px 0px;
    flex: 1;
    box-sizing: border-box;
    padding: 65px 30px 0;
    ::v-deep .van-cell {
      margin-bottom: 32px;
      border: none;
      padding: 0;
      .van-field__body {
        background: #f5f5fa;
        padding: 0 20px;
        font-size: 16px;
        height: 44px;
        line-height: 44px;
        border-radius: 22px;
        // .van-field__control {
        //   background: #f5f5fa !important;
        // }
      }
    }
    .van-button {
      background: #1284ff;
      border-radius: 22px;
      width: 100%;
      margin-top: 45px;
    }
  }
}
</style>
